<template>
  <div class="bg_fff container">
    <div class="header">
      <div class="cadd">技能云</div>
    </div>
    <div class="abso" @click="goTo">
      <img
        style="height: 0.42rem; width: 0.42rem; margin-right: 0.1rem"
        src="../../assets/add.png"
        alt=""
      />
      <div class="cadd">创建技能</div>
    </div>
    <div class="jinclass">
      <div @click="setListMsg(0)" :class="seindex == 0 ? 'xuanzhong' : ''">
        工作技能
      </div>
      <div @click="setListMsg(1)" :class="seindex == 1 ? 'xuanzhong' : ''">
        生活技能
      </div>
      <div @click="setListMsg(2)" :class="seindex == 2 ? 'xuanzhong' : ''">
        综合技能
      </div>
    </div>
    <div class="jin">
      <div v-show="allListMsg.length > 0">
        <div
          @click="
            toPage(
              item.mim_id,
              item.id,
              item.skill_name,
              item.skill_type,
              item.company_name,
              item.headimg_url,
              item.username
            )
          "
          v-for="(item, index) in allListMsg"
          :key="index"
          :class="item.count > 0 ? 'jinchild jinchild2' : 'jinchild '"
        >
          <div class="leftjin">
            <div>{{ seindex == 2 ? item.position_name : item.skill_name }}</div>
            <img
              v-if="item.count > 0"
              class="jinimg"
              src="../../assets/see_2.png"
              alt=""
            />
            <img v-else class="jinimg" src="../../assets/see_1.png" alt="" />
            <div :class="item.count > 0 ? 'nojin nojin2' : 'nojin '">
              {{ item.count > 0 ? item.count : "未点亮" }}
            </div>
          </div>
          <div
            v-if="seindex!=2"
            @click.stop="
              openCloseSkill(item.id, item.status == 1 ? 2 : 1, 1, seindex)
            "
            class="rightjin"
          >
            <img v-if="item.status == 1" src="../../assets/eye_2.png" alt="" />
            <img v-else src="../../assets/eye_1.png" alt="" />
            <div style="color: #4571d0" v-if="item.status == 1">显示</div>
            <div v-else>隐藏</div>
          </div>
          <div
            v-else
            @click.stop="
              openCloseSkill(item.id, item.is_hot == 2 ? 1 : 2, 2, seindex)
            "
            class="rightjin"
          >
            <img v-if="item.is_hot == 1" src="../../assets/eye_2.png" alt="" />
            <img v-else src="../../assets/eye_1.png" alt="" />
            <div style="color: #4571d0" v-if="item.is_hot == 1">显示</div>
            <div v-else>隐藏</div>
          </div>
        </div>
      </div>
      <div v-show="allListMsg.length < 1">
        <div class="flex1">
          <img class="img" src="../../assets/notel.png" alt="" />
          <div>- 你还没有任何技能标签哦 -</div>
        </div>
      </div>
    </div>
    <!-- <div v-if="showLoading == false">

      <div class="PLR_6">
        <div
          class="fontS_17 font_weight_bold color_333 textL"
          style="margin-top: 0.2rem"
        >
          工作技能
        </div>
        <div class="list" v-if="workList.length > 0">
          <div
            @click="
              toPage(
                item.mim_id,
                item.id,
                item.skill_name,
                item.skill_type,
                item.company_name,
                item.headimg_url,
                item.username
              )
            "
            v-for="(item, index) in workList"
            :key="index"
          >
            <div :class="item.status == 2 ? 'item' : 'itemActive'">
              {{ item.skill_name }} . {{ item.count }}
            </div>
          </div>
        </div>
        <div class="flex1" v-else>
          <img class="img" src="../../assets/notel.png" alt="" />
          <div>- 你还没有任何技能标签哦 -</div>
        </div>
      </div>
      <div class="PLR_6">
        <div class="fontS_17 font_weight_bold color_333 textL">生活技能</div>

        <div class="list" v-if="liftList.length > 0">
          <div
            class="item"
            @click="
              toPage(
                item.mim_id,
                item.id,
                item.skill_name,
                item.skill_type,
                item.company_name,
                item.headimg_url,
                item.username
              )
            "
            v-for="(item, index) in liftList"
            :key="index"
          >
            {{ item.skill_name }} . {{ item.count }}
          </div>
        </div>
        <div class="flex1" v-else>
          <img class="img" src="../../assets/notel.png" alt="" />
          <div>- 你还没有任何技能标签哦 -</div>
        </div>
      </div>
      <div class="PLR_6">
        <div class="fontS_17 font_weight_bold color_333 textL">更多技能</div>
        <div class="list" v-if="custom.length > 0">
          <div
            @click="
              toPage(
                0,
                item.id,
                item.position_name,
                item.skill_type,
                item.company_name,
                item.headimg_url,
                item.name
              )
            "
            :class="item.status == 2 ? 'item' : 'itemActive'"
            v-for="(item, index) in custom"
            :key="index"
          >
            {{ item.position_name }} . {{ item.count }}
          </div>
        </div>
        <div class="flex1" v-else>
          <img class="img" src="../../assets/notel.png" alt="" />
          <div>- 你还没有任何技能标签哦 -</div>
        </div>
      </div>
      <div style="height: 1.5rem"></div>
    </div> -->
    <div style="height:1.5rem"></div>
    <van-loading v-if="showLoading" size="45" class="loading" type="spinner" />
  </div>
</template>

<script>
import "@/assets/css/common.css";
import { Toast } from "vant";
export default {
  name: "",
  data() {
    return {
      title: "",
      workList: [],
      liftList: [],
      userinfo: {},
      allListMsg: [],
      custom: [],
      showLoading: false,
      seindex: 0, //菜单下标
    };
  },
  created() {},
  mounted() {
    // 获取工作技能、生活技能、 其他技能
    let id = localStorage.getItem("id");
    // console.log(id)
    this.showLoading = true;
    this.$http
      .post("/user/v1/Position/getSkill", { reqType: "getSkill", user_id: id })
      .then((res) => {
        let response = JSON.parse(res.data);
        console.log(response);
        if (response.code == 0) {
          this.showLoading = false;
          let list = response.data.skill_list;
          let workList = list.filter((value) => {
            return value.skill_type == 1;
          });
          let liftList = list.filter((value) => {
            return value.skill_type == 2;
          });

          // console.log( response.data.user_info)
          this.userinfo = response.data.user_info;
          this.workList = workList;
          this.liftList = liftList;
          this.setListMsg(0);
        } else {
          Toast.fail(response.msg);
        }
      })
      .catch((e) => {
        console.log(e);
      });

    //获取自定义技能
    this.$http
      .post("/user/v1/Position/getCustom", {
        reqType: "getCustom",
        user_id: id,
      })
      .then((res) => {
        res = JSON.parse(res.data);
        if (res.code == 0) {
          console.log(res.data);
          this.custom = res.data.custom;
        } else {
          Toast(res.msg);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  },
  methods: {
    /**
      显示隐藏技能
     */
    openCloseSkill(id, type, skill_type) {
        this.$http
          .post("/user/v1/Position/upStatus", {
            reqType: "upStatus",
            type: type, // 1-显示 2-隐藏藏,
            skill_id: id,
            skill_type: skill_type, //skill_type 1生活技能/XX 2:自定义技能
          })
          .then(() => {
            this.$toast(type == 1 ? "已显示" : "已隐藏");
            setTimeout(()=>{
              location.reload();
            },500)
          });
    },
    /**
     * 设置列表值
     */
    setListMsg(index) {
      this.seindex = index;
      switch (index) {
        case 0:
          this.allListMsg = this.workList;
          break;
        case 1:
          this.allListMsg = this.liftList;
          break;
        case 2:
          this.allListMsg = this.custom;
          break;
      }
    },
    goTo() {
      this.$router.push("/choiceTag");
    },
    toPage(mim_id, id, skillname, type, company_name, headimg_url, username) {
      console.log(username);
      this.$router.push({
        path: "/tagDetail",
        query: {
          mim_id: mim_id,
          id: id,
          skillname: skillname,
          type: type,
          company_name: company_name,
          headimg_url: headimg_url,
          username: username,
        },
      });
    },
  },
};
</script>

<style scoped>
.rightjin {
  display: flex;
  align-items: center;
  font-size: 0.28rem;
  font-weight: 400;
  color: #c0c4cc;
  line-height: 0.4rem;
}
.rightjin > img {
  width: 0.32rem;
  margin-right: 0.1rem;
}
.nojin {
  font-size: 0.24rem;
  font-weight: 400;
  color: #c0c4cc;
  line-height: 0.34rem;
}
.nojin2 {
  color: #ff6d20;
}
.leftjin {
  display: flex;
  align-items: center;
}
.jinimg {
  width: 0.28rem;
  margin: 0 0.1rem;
}
.jinchild {
  border-radius: 0.46rem;
  border: 0.02rem solid #c0c4cc;
  padding: 0.26rem 0.34rem;
  font-size: 0.28rem;
  font-weight: 400;
  color: #606266;
  line-height: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.24rem;
}
.jinchild2 {
  border: 0.02rem solid #ff6d20;
  color: #ff6d20;
}
.jin {
  margin: 0.34rem;
}
.xuanzhong {
  color: #ff6d20;
  position: relative;
}
.xuanzhong::after {
  content: "";
  position: absolute;
  width: 0.44rem;
  height: 0.1rem;
  background: #ff6d20;
  border-radius: 0.05rem;
  bottom: -0.23rem;
  left: 50%;
  transform: translateX(-50%);
}
.jinclass {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.32rem;
  font-weight: 500;
  color: #606266;
  line-height: 0.48rem;
  padding: 0.24rem 0;
  box-shadow: 0rem 0.02rem 0rem 0rem #ebeef5;
}
.jinclass > :nth-child(2) {
  margin: 0 0.82rem;
}
.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -0.2rem;
}

.cadd {
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}
.abso {
  float: right;
  margin-top: -1rem;
  display: flex;
  align-items: center;
  margin-right: 0.2rem;
}
.header {
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sex {
  width: 0.4rem;
  height: 0.4rem;
  margin-left: 0.2rem;
}
.title {
  margin-top: 0.2rem;
}
.list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
}
.item {
  height: 0.79rem;
  background: #ffffff;
  border-radius: 0.4rem;
  border: 0.02rem solid #979797;
  line-height: 0.79rem;
  padding: 0 0.3rem;
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  margin-right: 0.2rem;
  margin-top: 0.2rem;
}
.itemActive {
  height: 0.79rem;
  background: #ffffff;
  border-radius: 0.4rem;
  border: 0.02rem solid #ff5f19;
  line-height: 0.79rem;
  padding: 0 0.3rem;
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ff5f19;
  margin-right: 0.2rem;
  margin-top: 0.2rem;
}
.container {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #fff;
  top: 0;
  bottom: 0;
  overflow-y: scroll;
  overflow-x: hidden;
}
.flex1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 0;
}
.img {
  width: 2rem;
  height: 1.8rem;
}
.tx {
  width: 1.25rem;
  height: 1.25rem;
}
.flex {
  display: flex;
  align-items: center;
}
</style>